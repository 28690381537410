<script lang="ts" setup>
interface VCollapsibleProps {
    headClass?: string
    defaultOpened?: boolean
    tag?: string
}

const props = defineProps<VCollapsibleProps>()

const isOpen = ref(props.defaultOpened ?? false)
const toggle = () => (isOpen.value = !isOpen.value)

const id = useId()
const bodyId = 'collapsible-body-' + id

function afterEnter(element: HTMLElement) {
    element.style.height = 'auto'
}

function enter(element: HTMLElement) {
    element.style.width = getComputedStyle(element).width
    element.style.position = 'absolute'
    element.style.visibility = 'hidden'
    element.style.height = 'auto'

    const height = getComputedStyle(element).height

    element.style.width = ''
    element.style.position = ''
    element.style.visibility = ''
    element.style.height = '0'

    // Force repaint to make sure the
    // animation is triggered correctly.
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    getComputedStyle(element).height

    requestAnimationFrame(() => {
        element.style.height = height
    })
}

function leave(element: HTMLElement) {
    element.style.height = getComputedStyle(element).height

    // Force repaint to make sure the
    // animation is triggered correctly.
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    getComputedStyle(element).height

    requestAnimationFrame(() => {
        element.style.height = '0'
    })
}
</script>

<template>
    <component :is="tag || 'div'">
        <button
            :aria-expanded="isOpen"
            :aria-controls="bodyId"
            :class="[$style.head, headClass]"
            @click="toggle"
        >
            <slot
                name="head"
                :is-open="isOpen"
            />
        </button>
        <Transition
            :name="$style.expand"
            @after-enter="afterEnter"
            @enter="enter"
            @leave="leave"
        >
            <slot
                name="body"
                :is-open="isOpen"
                :body-id="bodyId"
            />
        </Transition>
    </component>
</template>

<style lang="scss" module>
.head {
    border: initial;
    background: inherit;
    color: initial;
    padding-inline: inherit;
}

.expand {
    &:global(#{'-enter-active'}),
    &:global(#{'-leave-active'}) {
        overflow: hidden;
        transition:
            opacity 0.3s,
            height 0.3s;
    }

    &:global(#{'-enter'}),
    &:global(#{'-leave-to'}) {
        height: 0;
        opacity: 0;
    }
}
</style>
