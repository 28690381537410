import type { LocationAsRelativeRaw } from 'vue-router'
import { NuxtLink } from '#components'

export interface useUrlResolverOptions {
    tag?: string
    href?: string | null
    to?: string | LocationAsRelativeRaw
}

export function useLinkResolver(linkOptions: useUrlResolverOptions) {
    const router = useRouter()
    const isExternalLink = useNuxtApp?.()?.$isExternalLink

    const internalRouterUrl = computed(() => {
        return linkOptions.to && typeof linkOptions.to === 'object' && router.resolve(linkOptions.to)?.href
    })

    const url = computed(() => {
        if (typeof linkOptions.to === 'string') return linkOptions.to
        else if (internalRouterUrl.value) return internalRouterUrl.value
        else if (linkOptions.href) return linkOptions.href
    })

    const isExternal = computed(() => url.value && isExternalLink(url.value))

    const isRelativePath = computed(() => url.value?.charAt(0) === '/')
    const isAnchor = computed(() => url.value?.charAt(0) === '#')
    const isInternal = computed(() => (isRelativePath.value || isAnchor.value) && !isExternal.value)

    const tagOutput = computed(() => {
        if (typeof linkOptions.tag === 'string') return linkOptions.tag

        if (isInternal.value) return NuxtLink
        else if (isExternal.value) return 'a'
        else return 'button'
    })

    const target = computed(() => (isExternal.value ? '_blank' : undefined))
    const relation = computed(() => (isExternal.value ? 'noopener nofollow' : undefined))

    const linkAttributes = computed(() => {
        const result: Record<string, unknown> = {}

        if (isExternal.value) {
            result.href = url.value
            result.target = target.value
            result.rel = relation.value
        }
        else if (isInternal.value) {
            result.to = internalRouterUrl.value ? linkOptions.to : url.value
        }

        return result
    })

    return { linkAttributes, tagOutput, url }
}
