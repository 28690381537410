<script setup lang="ts">
interface VLoaderProps {
    width?: string
    stroke?: string
    dashArray?: string
    speed?: string
}

const props = defineProps<VLoaderProps>()

const size = computed(() => Number(props.width || 200))
const strokeWidth = computed(() => props.stroke || '10')
const strokeDashArray = computed(() => props.dashArray || '100 100')
const duration = computed(() => props.speed || '1')

const translate = computed(() => size.value * 0.25)
const radius = computed(() => size.value / 2 - Number(strokeWidth.value))
</script>

<template>
    <svg
        ref="icon"
        :width="`${size}px`"
        :height="`${size}px`"
        :viewBox="`0 0 ${size} ${size}`"
    >
        <g :transform="`translate(${translate},${translate})`">
            <circle
                :cx="translate"
                :cy="translate"
                :r="radius"
                :stroke-width="strokeWidth"
                stroke="currentColor"
                :stroke-dasharray="strokeDashArray"
                fill="none"
                stroke-linecap="round"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    :dur="`${duration}s`"
                    keyTimes="0;1"
                    :values="`0 ${translate} ${translate};360 ${translate} ${translate}`"
                />
            </circle>
        </g>
    </svg>
</template>
