<script setup lang="ts">
import type { LocationAsRelativeRaw } from 'vue-router'

interface Reference {
    ctaLabel?: string | null
    ctaLink?: string | null
    title?: string | null
}

interface VLinkProps {
    to?: LocationAsRelativeRaw | string
    href?: string | null
    custom?: boolean
    label?: string
    reference?: Reference
}

const props = defineProps<VLinkProps>()

const { linkAttributes, url } = useLinkResolver({
    to: props.to,
    href: props.href || props.reference?.ctaLink,
})

const label = computed(() => {
    return props.label || props.reference?.ctaLabel || props.reference?.title
})

const linkProps = computed(() => {
    return { ...linkAttributes.value, label: label.value }
})
</script>

<template>
    <slot
        v-if="custom || !url"
        :link-attrs="linkProps"
    />
    <NuxtLink
        v-else
        v-bind="linkProps"
    >
        <slot />
    </NuxtLink>
</template>
