<script lang="ts" setup>
import type { ContentBlock } from '~/types/api'
import type { IpxImage } from '~/types/app'

interface VHeaderContentBlock {
    contentBlock: ContentBlock
}

const props = defineProps<VHeaderContentBlock>()

const fallBackImage: IpxImage = {
    alt: props.contentBlock.title || 'Mingat: votre loueur de véhicule',
    relativePath: '/error-banner.jpg',
}
</script>

<template>
    <VHeader
        :image="contentBlock.image"
        :title="contentBlock.title"
        :ipx-image="fallBackImage"
        layout="higher"
        :class="$style.root"
    />
</template>

<style lang="scss" module="">
.root {
    --v-header-body-padding-bottom: calc(var(--v-reservation-offset-top) * -1 + #{rem(54)});
    --v-header-title-max-width: 15ch;
}
</style>
