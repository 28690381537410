export const DAY_LABELS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] as const
export type DayName = (typeof DAY_LABELS)[number]

/**
 * Parse an UTC date representation.
 */
export const parseReservationDate = (date: string | undefined): undefined | Date => {
    if (!date) return undefined
    const regex = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/
    const groups = date.match(regex) as string[]

    if (groups.length !== 6) throw new Error('Invalid date format')

    return new Date(`${groups[1]}-${groups[2]}-${groups[3]}T${groups[4]}:${groups[5]}:00Z`)
}

/**
 * Format a date to an UTC date representation.
 */
export const formatReservationTime = (date: Date): string => {
    return (
        date.getUTCFullYear()
        + ('0' + (date.getUTCMonth() + 1)).slice(-2)
        + ('0' + date.getUTCDate()).slice(-2)
        + ('0' + date.getUTCHours()).slice(-2)
        + ('0' + date.getUTCMinutes()).slice(-2)
    )
}

/**
 * Format a UTC date for an input date representation with timezone offset.
 */
export const formatReservationDateForInput = (date: Date | undefined): Date | undefined => {
    if (!date) return undefined
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
    return new Date(date.toISOString().slice(0, 16))
}

// @deprecated the minDepartureHoursDelta is now dynamic, it is specific for each agency
export const isDepartureDateValid = (date: Date | undefined): boolean => {
    if (!date) return false
    const minDepartureHoursDelta = 23.5
    const nextDayDepartureDate = new Date(Date.now() + minDepartureHoursDelta * 60 * 60 * 1000)
    return date >= nextDayDepartureDate
}

export const isReservationDateValid = (date: string | undefined): boolean => {
    if (!date) return false
    return /^\d{4}\d{2}\d{2}\d{2}(?:00|30)$/.test(date)
}

export const getDateFromNow = (dayCount: number) => {
    const today = new Date()
    const newDate = new Date(today)

    newDate.setDate(today.getDate() + dayCount)

    return newDate
}

export const isDateInCurrentWeek = (date: string) => {
    const todayObj = new Date()
    const todayDate = todayObj.getDate()
    const todayDay = todayObj.getDay()

    // get first date of week
    const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay + 1))

    // get last date of week
    const lastDayOfWeek = new Date(firstDayOfWeek)
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6)

    return new Date(date) >= firstDayOfWeek && new Date(date) <= lastDayOfWeek
}

export function getCurrentWeek(current: Date = new Date()) {
    const week = []

    // Starting Monday not Sunday
    current.setDate(current.getDate() - current.getDay() + 1)

    for (let i = 0; i < 7; i++) {
        const date = new Date(current)
        week.push({
            label: date.toLocaleDateString('en-EN', { weekday: 'long' }) as DayName,
            date: date.toISOString(),
        })
        current.setDate(current.getDate() + 1)
    }

    return week
}
