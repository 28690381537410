import { isJwtExpired, parseJwt } from '~/utils/jwt'
import type { User } from '~/types/api'

export default defineNuxtRouteMiddleware(async (to) => {
    // Only run on client side, Nuxt instance is not available on server side
    // if (process.server) return
    const { $apiFetch } = useNuxtApp()
    const session = useSessionCookie()
    const currentUser = useCurrentUserState()
    if (session.value?.token) {
        try {
            const jwt = parseJwt(session.value.token)
            if (!jwt || isJwtExpired(jwt)) {
                return
            }
            if (!currentUser.value) {
                currentUser.value = await $apiFetch<User>('/me')
            }
            if (currentUser.value) {
                if (!to.query.redirect || !(to.query.redirect as string).startsWith('/')) return
                return navigateTo((to.query.redirect as string) || '/')
            }
        }
        catch (e) {
            // if fetching current user fails empty session before throwing
            session.value = undefined
            throw e
        }
    }
})
