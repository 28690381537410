interface ParsedJwt {
    username: string
    roles: string[]
    exp: number
}

export function parseJwt(token: string | undefined): ParsedJwt | null {
    let decodedBase64

    if (!token) {
        return null
    }

    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')

    if (typeof window !== 'undefined' && typeof window.atob !== 'undefined') {
        decodedBase64 = window.atob(base64)
    }
    else {
        decodedBase64 = Buffer.from(base64, 'base64').toString()
    }

    const jsonPayload = decodeURIComponent(
        decodedBase64
            .split('')
            .map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join(''),
    )

    return JSON.parse(jsonPayload)
}

export function isJwtExpired(jwt: ParsedJwt | undefined): boolean {
    if (!jwt || !jwt.exp) {
        return true
    }
    return jwt.exp < Date.now() / 1000
}

export function isJwtRefreshable(jwt: ParsedJwt | undefined, refreshTokenExpiration: number | undefined): boolean {
    if (!jwt || !refreshTokenExpiration) {
        return false
    }
    return isJwtExpired(jwt) && refreshTokenExpiration > Date.now() / 1000
}
