import type { FetchError } from 'ofetch'
import type { JsonLdCollection, Menu } from '~/types/api'

export default defineNuxtRouteMiddleware(async () => {
    // Only run on server side
    if (!import.meta.server) return
    const { $apiFetch } = useNuxtApp()
    const menusState = useMenusState()
    if (!menusState.value) {
        try {
            menusState.value = (await $apiFetch<JsonLdCollection<Menu>>('/menus'))?.['hydra:member']
        }
        catch (e: unknown) {
            throw createError({
                statusCode: 503,
                message: (e as FetchError).message,
            })
        }
    }
})
