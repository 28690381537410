import type { FetchError, type FetchOptions } from 'ofetch'
import type { Ref } from 'vue'
import type { Agency, JsonLdCollection, Service, Session, VehicleCategory, VehicleCategoryPrice } from '~/types/api'
import { formatCurrency } from '~/utils/currency'
import { parseJwt } from '~/utils/jwt'

const commonHeaders = (options?: FetchOptions): Record<string, string> => {
    const headers = {
        ...(options?.headers || {
            'accept-encoding': 'gzip, deflate',
        }),
    } as Record<string, string>

    if (!headers.Accept) {
        headers.Accept = 'application/ld+json'
    }

    return headers
}

/*
 * Factory: create a new fetch instance with common headers and base URL.
 */
export const apiFetchFactory = () => {
    // useSessionCookie must be called outside of the factory
    // https://stackoverflow.com/a/77196659
    const session = useSessionCookie()
    return $fetch.create({
        onRequest({ options }) {
            if (session.value?.token) {
                const jwt = parseJwt(session.value.token)
                if (jwt && jwt.exp > Date.now() / 1000) {
                    options.headers = {
                        ...options.headers,
                        Authorization: `Bearer ${session.value.token}`,
                    }
                }
            }
        },
        headers: commonHeaders(),
        baseURL: useApiUrl(),
    })
}

const refreshToken = async (): Promise<void> => {
    const session = useSessionCookie()
    if (!session.value?.refresh_token) return
    try {
        session.value = await $fetch<Session>(`${useApiUrl()}/token/refresh`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                refresh_token: session.value.refresh_token,
            }),
        })
    }
    catch (error: unknown) {
        const fetchError = error as FetchError
        if (fetchError?.data && fetchError.statusCode !== 422) {
            throw createError({
                statusCode: fetchError.statusCode,
                message: fetchError?.data.message,
                fatal: true,
            })
        }
        throw error
    }
}

const getToken = async (email: string, password: string): Promise<void> => {
    try {
        const session = useSessionCookie()
        session.value = await $fetch<Session>(`${useApiUrl()}/token`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                password,
            }),
        })
    }
    catch (error: unknown) {
        const fetchError = error as FetchError
        if (fetchError?.data && fetchError.statusCode !== 422) {
            throw createError({
                statusCode: fetchError.statusCode,
                message: fetchError?.data.message,
                fatal: true,
            })
        }
        throw error
    }
}

const fetchVehicleCategoryPrice = (
    vehicleCategory: VehicleCategory,
    agency: Agency,
    departureTime: Ref<Date | undefined>,
    returnTime: Ref<Date | undefined>,
    totalKilometersCount: Ref<number | undefined>,
): Promise<VehicleCategoryPrice> => {
    const { $apiFetch } = useNuxtApp()
    return $apiFetch<VehicleCategoryPrice>(`/vehicle_category_prices/${vehicleCategory.id}`, {
        method: 'GET',
        query: {
            agency: agency['@id'],
            departure: departureTime.value?.toISOString(),
            return: returnTime.value?.toISOString(),
            totalKilometersCount: totalKilometersCount.value,
        },
    })
}

const fetchAgenciesIds = (agencyCode: Ref<string | undefined>): Promise<JsonLdCollection<Agency>> => {
    const { $apiFetch } = useNuxtApp()
    return $apiFetch<JsonLdCollection<Agency>>('/agencies', {
        method: 'GET',
        query: {
            'mrcAgencyCode': agencyCode.value,
            'properties[0]': 'mrcAgencyCode',
            'properties[1]': 'id',
            'properties[2]': 'title',
        },
    })
}

const fetchVehicleCategoriesIds = (vehicleCategoryCode: Ref<string | undefined>) => {
    const { $apiFetch } = useNuxtApp()
    return $apiFetch<JsonLdCollection<VehicleCategory>>('/vehicle_categories', {
        method: 'GET',
        query: {
            'mrcCategoryCode': vehicleCategoryCode.value,
            'properties[0]': 'id',
            'order[position]': 'asc',
        },
    })
}

const fetchServices = (
    agency: Agency,
    vehicleCategory: VehicleCategory,
    departureTime: Ref<Date | undefined>,
    returnTime: Ref<Date | undefined>,
    totalKilometersCount: Ref<number | undefined>,
): Promise<JsonLdCollection<Service>> => {
    const { $apiFetch } = useNuxtApp()
    return $apiFetch<JsonLdCollection<Service>>(`/services`, {
        method: 'GET',
        query: {
            agency: agency['@id'],
            departure: departureTime.value?.toISOString(),
            return: returnTime.value?.toISOString(),
            vehicleCategory: vehicleCategory['@id'],
            totalKilometersCount: totalKilometersCount.value,
        },
    })
}

export default defineNuxtPlugin(() => {
    return {
        provide: {
            refreshToken,
            getToken,
            apiFetch: apiFetchFactory(),
            formatCurrency,
            fetchVehicleCategoryPrice,
            fetchServices,
            fetchAgenciesIds,
            fetchVehicleCategoriesIds,
        },
    }
})
