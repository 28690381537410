<script lang="ts" setup>
import type { ContentBlock } from '~/types/api'

interface VDefaultContentBlock {
    contentBlock: ContentBlock
    tag?: string
}
const props = defineProps<VDefaultContentBlock>()

const isImgTextLayout = computed(() => props.contentBlock.type === 'image_text')
const isFullLayout = computed(
    () => props.contentBlock.type === 'light_full' || props.contentBlock.type === 'dark_full' || isImgTextLayout.value,
)
const isSimpleTextLayout = computed(() => props.contentBlock.type === 'simple_text')
const isCardLayout = computed(() => props.contentBlock.type === 'card')

const mediaAttributes = computed(() => {
    if (isImgTextLayout.value) {
        return {
            sizes: '400px sm:90vw md:90vw lg:40vw xl:40vw xxl:50vw hd:50vw',
            width: '456',
            height: '204',
            fit: 'cover',
        }
    }
    else if (props.contentBlock.type === 'dark_full') {
        return {
            sizes: '400px sm:90vw md:80vw lg:50vw xl:60vw xxl:60vw hd:80vw',
            width: '794',
            height: '336',
        }
    }
    else if (props.contentBlock.type === 'light_full') {
        return {
            sizes: '400px sm:90vw md:90vw lg:40vw xl:40vw xxl:50vw hd:50vw',
            width: '456',
            height: '204',
        }
    }
    else if (isCardLayout.value) {
        return {
            sizes: 'sm:90vw md:90vw lg:40vw xl:40vw hd:40vw',
            width: '307',
            height: '172',
            fit: 'cover',
        }
    }

    return undefined
})

if (props.contentBlock.type === 'dark_full') useThemeProvider({ preferredTheme: 'dark' })

const $style = useCssModule()
const rootClasses = computed(() => {
    return [
        $style.root,
        isFullLayout.value && $style['root--layout-full'],
        isSimpleTextLayout.value && $style['root--layout-simple-text'],
        // Layout image_text has same design than light_full
        isImgTextLayout.value && $style[`root--layout-light_full`],
        $style[`root--layout-${props.contentBlock.type}`],
    ]
})
</script>

<template>
    <component
        :is="tag || 'div'"
        :key="contentBlock.id"
        :class="rootClasses"
    >
        <VLinkWrapper
            :href="contentBlock.ctaLink"
            :class="$style['image-link']"
            tabindex="-1"
            rel="noopener nofollow"
            :title="contentBlock.title"
        >
            <NuxtPicture
                v-if="contentBlock.image?.processable"
                provider="interventionRequest"
                :src="contentBlock.image?.relativePath"
                :alt="contentBlock.title"
                :class="$style.media"
                loading="lazy"
                v-bind="mediaAttributes"
            />
            <div
                v-else
                :class="[$style.media, $style['media--placeholder']]"
            />
        </VLinkWrapper>
        <div :class="$style.body">
            <h2
                v-if="!isSimpleTextLayout"
                class="text-h2 cap-black-italic"
                :class="$style.title"
            >
                {{ contentBlock.title }}
            </h2>
            <VMarkdown
                v-if="contentBlock.text"
                :content="contentBlock.text"
                inline
                :class="$style.text"
                class="text-body-m"
            />
            <VLinkWrapper
                v-slot="{ linkAttrs }"
                custom
                :reference="contentBlock"
            >
                <VButton
                    v-if="contentBlock.ctaLink"
                    :class="$style.button"
                    outlined
                    :size="isCardLayout ? 's' : 'm'"
                    v-bind="linkAttrs"
                    icon-name="arrow-small-right"
                />
            </VLinkWrapper>
        </div>
    </component>
</template>

<style lang="scss" module>
@use 'sass:math';

.root {
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;
    margin-block: rem(24);

    &--layout-full {
        overflow: hidden;
        min-height: rem(230);
        justify-content: center;
        border-radius: var(--radius-md);
    }

    &--layout-light_full {
        background-color: var(--color-white);
    }

    &--layout-dark_full {
        --v-button-border-color: var(--color-white);
        --v-button-color: var(--color-white);

        min-height: rem(320);
        background-color: color(primary);
        color: var(--color-white);
    }

    &--layout-simple-text {
        max-width: rem(780);

        // Need to override margin-block from VContentBlockFactory
        margin-block: rem(12) !important;
        margin-inline: auto;
    }

    @include media('>=md') {
        &--layout-full {
            flex-wrap: nowrap;
            align-items: center;
            justify-content: initial;
        }
    }

    @include media('>=vl') {
        margin-block: rem(48);
    }
}

.image-link {
    display: contents;
}

.media {
    position: relative;
    width: 100%;
    flex-shrink: 0;

    & img {
        width: 100%;
        height: auto;
    }

    &::before {
        position: absolute;
        background: rgb(1 1 1 / 5%);
        content: '';
        inset: 0;
    }

    .root--layout-dark_full &::before {
        background: linear-gradient(to bottom, transparent, color(black));
        opacity: 0.6;
    }

    .root--layout-card & {
        overflow: hidden;
        height: fit-content;
        border-radius: var(--radius-md);
        margin-bottom: rem(20);
        aspect-ratio: 307 / 172;
    }

    .root--layout-card &--placeholder {
        aspect-ratio: 307 / 172;
        background-color: color(black-03);
    }

    .root--layout-full & {
        aspect-ratio: 327 / 228;
        clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);

        img {
            height: 100%;
            object-fit: cover;
        }
    }

    .root--layout-full &--placeholder {
        height: 100%;
        background-color: color(grey-100);
    }

    @include media('>=md') {
        .root--layout-full & {
            width: 50%;
            align-self: stretch;
            aspect-ratio: initial;
            clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
        }

        .root--layout-image_text & {
            aspect-ratio: 453 / 246;
        }
    }

    @include media('>=lg') {
        .root--layout-full & {
            width: initial;
            max-width: 70%;
            flex-grow: 1;
            aspect-ratio: initial;
        }

        .root--layout-light_full & {
            width: 100%;
            max-width: rem(456);
        }
    }
}

.body {
    flex-direction: column;

    .root--layout-full & {
        padding-block: rem(32) rem(40);
        padding-inline: rem(24);
        text-align: center;
    }

    @include media('>=md') {
        .root--layout-full & {
            width: 50%;
            text-align: initial;
        }
    }

    @include media('>=lg') {
        .root--layout-full & {
            max-width: 46ch;
            padding-block: rem(48);
            padding-inline: rem(72) rem(62);
        }

        .root--layout-light_full & {
            display: flex;
            width: 100%;
            max-width: initial;
            flex-flow: row wrap;
            justify-content: space-between;
        }
    }
}

.title {
    flex-grow: 1;

    .root--layout-light_full & {
        width: 100%;
    }
}

.text {
    margin-top: rem(12);

    .root--layout-card & {
        max-width: min(90%, 40ch);
    }

    .root--layout-full & {
        margin-top: rem(16);
    }

    @include media('>=lg') {
        .root--layout-light_full & {
            max-width: 50ch;
        }
    }
}

.button {
    margin-top: rem(24);

    .root--layout-full & {
        margin-top: rem(32);
    }

    @include media('>=md') {
        .root--layout-full & {
            float: right;
        }
    }

    @include media('>=lg') {
        .root--layout-full & {
            align-self: flex-end;
        }
    }
}
</style>
